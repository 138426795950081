import React, { useState } from 'react'
import getConfig from 'next/config'
import clsx from 'clsx'
import { useRouter, ContactSellerReasonType, sellerRoutes } from '@src/routes'
import { ApolloError } from 'apollo-boost'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MUILink from '@material-ui/core/Link'
import ErrorIcon from '@material-ui/icons/Error'
import { Link } from '@src/components/Link'
import SelectDtaqDealerModal from '@src/components/SelectDtaqDealerModal'
import { extractData } from '@utils/errorHandler'
import messages from '@utils/messages'
import { useCurrentUser } from '@utils/useCurrentUser'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { AddToCartFormValues } from './ProductPriceAvailabilityFormBody'
import { TrackedAvailabilityMessage } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessage'

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

export const useStyles = makeStyles((theme: Theme) => {
  return {
    row: {
      display: 'flex',
    },
    rowFormatting: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    errorText: {
      lineHeight: 1.75,
    },
    errorContact: {
      display: 'flex',
      alignItems: 'center',
    },
    seeDealerOptionsLink: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      padding: 0,
      marginLeft: theme.spacing(1),
      textTransform: 'capitalize',
    },
    centerJustified: {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 500,
    },
  }
})

const ProductPriceAvailabilityError: React.FC<{
  error?: ApolloError
  productNumber?: string
  isMarketplace?: boolean
  sellerName?: string
  marketplaceSellerId?: string
  marketplaceOfferId?: string
  materialPricing?: Record<string, unknown>[]
  isCenterJustified?: boolean
  handlePriceUpdateForDealer?: (
    dealerId: string | null,
    values: AddToCartFormValues,
    materialPricing: ValidMaterialPricingDetailFragment[],
    setFieldValue: (field: string, value: any) => void,
    setIsModalOpen: (value: React.SetStateAction<boolean>) => void,
    dealerName: Maybe<string> | undefined
  ) => Promise<void>
}> = ({
  error,
  productNumber,
  isMarketplace = false,
  sellerName = '',
  marketplaceSellerId = '',
  marketplaceOfferId = '',
  materialPricing,
  isCenterJustified = true,
  handlePriceUpdateForDealer,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const { isDTAQZuCustomer } = useCurrentUser()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const errorObject = error ? extractData(error).errors[0] : undefined
  const hideTechnicalServiceLink =
    errorObject?.misc?.hideTechnicalServiceLink !== undefined
      ? errorObject.misc.hideTechnicalServiceLink
      : true
  const mPartner =
    isMarketplace &&
    !!sellerName &&
    !!marketplaceSellerId &&
    !!marketplaceOfferId

  const showPreferredDealerSection =
    featureFlags.dealerCartEnabled && isDTAQZuCustomer

  const errorMessage =
    (errorObject?.code && messages[errorObject.code]) ||
    messages.PRICING_AND_AVAILABILITY_UNAVAILABLE

  return (
    <div
      className={clsx(classes.row, {
        [classes.rowFormatting]: isCenterJustified,
      })}
    >
      <Box mr={2}>
        <ErrorIcon color="error" />
      </Box>
      <Box className={clsx({ [classes.centerJustified]: isCenterJustified })}>
        <span className={clsx({ [classes.errorText]: isCenterJustified })}>
          {errorObject?.code ===
          'EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE' ? (
            <TrackedAvailabilityMessage
              {...errorMessage}
              values={{
                link: <a href="http://www.emdmillipore.com/">Millipore</a>,
              }}
            />
          ) : errorObject?.code ===
            'MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE' ? (
            <TrackedAvailabilityMessage
              {...errorMessage}
              values={{
                link: <a href="http://www.merckmillipore.com/">Merck</a>,
              }}
            />
          ) : errorObject?.code === 'CUSTOMER_NUMBER_BLOCKED' ? (
            <TrackedAvailabilityMessage
              {...errorMessage}
              values={{
                customerNumber: errorObject?.paramList
                  ? errorObject?.paramList[0]
                  : '',
              }}
            />
          ) : productNumber ? (
            <TrackedAvailabilityMessage
              {...errorMessage}
              values={{ productNumber: <b>{productNumber}</b> }}
            />
          ) : (
            <TrackedAvailabilityMessage {...errorMessage} />
          )}
        </span>
        {!hideTechnicalServiceLink && !mPartner ? (
          <span>
            <span>&nbsp;</span>
            <Link href={'/support/customer-support'} passHref>
              <MUILink color="primary" className={classes.errorText}>
                <FormattedMessage {...messages.CONTACT_TECHNICAL_SERVICE} />
              </MUILink>
            </Link>
          </span>
        ) : null}
        {mPartner ? (
          <span className={classes.errorContact}>
            <span>&nbsp;</span>
            <Link
              {...sellerRoutes.contact(
                sellerName,
                marketplaceSellerId,
                router.asPath,
                ContactSellerReasonType.OFFER_MESSAGING,
                marketplaceOfferId as string
              )}
            >
              <a>
                <FormattedMessage
                  {...messages.CONTACT_SELLER_WITH_NAME}
                  values={{
                    sellerName,
                  }}
                />
              </a>
            </Link>
          </span>
        ) : null}
        {showPreferredDealerSection && (
          <Button
            variant="text"
            className={classes.seeDealerOptionsLink}
            onClick={() => setIsModalOpen(true)}
          >
            <FormattedMessage {...messages.SEE_ALL_BUYING_OPTIONS} />
          </Button>
        )}
        {showPreferredDealerSection &&
          handlePriceUpdateForDealer &&
          isModalOpen && (
            <SelectDtaqDealerModal
              values={{}}
              materialPricing={
                materialPricing as ValidMaterialPricingDetailFragment[]
              }
              isSavingDealerPreference={false}
              setFieldValue={(value) => value}
              setIsModalOpen={setIsModalOpen}
              onDealerSelect={(selectedDealerId, selectedDealerName) =>
                handlePriceUpdateForDealer(
                  selectedDealerId,
                  {},
                  materialPricing as ValidMaterialPricingDetailFragment[],
                  (value) => value,
                  setIsModalOpen,
                  selectedDealerName
                )
              }
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              disabled={false}
            />
          )}
      </Box>
    </div>
  )
}

export default ProductPriceAvailabilityError

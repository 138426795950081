import {
  Grid,
  IconButton,
  Link as MuiLink,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'
import Upload from '@src/components/Upload'
import DownloadIcon from '@src/icons/DownloadIcon'
import {
  DiscontinuedMaterialPricingDetailFragment,
  ProductPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import messages from '@utils/messages'
import { Field, FormikProps } from 'formik'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { sendQuickOrderPandAErrorEvent } from '@utils/analytics/pricingAndAvailability'
import ValidationErrors from './ValidationErrors'
import { QueryResult } from '@src/utils/QueryResult'
import { TrackedAvailabilityMessage } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import vrStyles from '@src/styles/utils/vrStyles'
import { fieldsetReset, legendReset } from '@src/styles/utils/formElementReset'
import clsx from 'clsx'
import { sendDownloadFileEvent } from '@src/utils/analytics'

const { vr2 } = vrStyles

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    materialInputLabel: {
      marginBottom: theme.spacing(2),
    },
    bulkUploadInput: {
      '& > textarea': {
        resize: 'vertical',
        margin: theme.spacing(1, 1.5, 0, 3),
        padding: theme.spacing(0, 1.5, 1.5, 0),
        fontSize: theme.typography.pxToRem(16),
      },
    },
    bulkUploadTemplateButton: {
      fontSize: theme.typography.pxToRem(14),
      padding: 0,
      borderRadius: 0,
      color: theme.palette.secondary.light,
      marginTop: theme.spacing(2),
      '&:hover': {
        background: 'none',
        textDecoration: 'none',
        borderBottom: 'none',
      },
    },
    bulkUploadTemplateIcon: {
      display: 'flex',
      height: theme.typography.pxToRem(14),
      width: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
    },
    bulkUploadTemplateText: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    validationError: {
      color: theme.palette.error.main,
      fontSize: theme.typography.pxToRem(12),
    },
    modalLink: {
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
    },
    fieldset: {
      ...fieldsetReset(),
    },
    legend: {
      ...legendReset(),
    },
    vr2,
  })
)

interface BulkUploadProps<T> {
  formikBag: FormikProps<any>
  validationErrors: string[]
  setValidationErrors: React.Dispatch<React.SetStateAction<string[]>>
  pricingErrors: {
    [productNumber: string]: DiscontinuedMaterialPricingDetailFragment
  }
  setModalOpen: React.Dispatch<
    React.SetStateAction<ProductPricingDetailFragment | null>
  >
  pricing: Partial<{
    [materialNumber: string]: QueryResult<ProductPricingDetailFragment>
  }>
  formInstructions?: JSX.Element
  helperText?: JSX.Element
  parseSpreadsheet: (file: File) => Promise<T>
  validate: (
    values: string,
    setValidationErrors: React.Dispatch<React.SetStateAction<string[]>>
  ) => { hasValidationErrors: boolean }
  dlType: string
  fileName: string
  formatEntry: (arg: T) => string
  onBlur?: () => void
}

/**
 * Bulk Upload
 *
 * Does not include Form element
 */
const BulkUpload = <T,>({
  formikBag,
  validationErrors,
  setValidationErrors,
  pricingErrors,
  pricing,
  setModalOpen,
  formInstructions,
  helperText,
  parseSpreadsheet,
  validate,
  dlType,
  fileName,
  formatEntry,
  onBlur,
}: BulkUploadProps<T>): JSX.Element => {
  const theme = useTheme()
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  React.useEffect(() => {
    sendQuickOrderPandAErrorEvent(pricingErrors)
  }, [pricingErrors])

  const { setFieldTouched, setFieldValue } = formikBag
  return (
    <fieldset className={classes.fieldset}>
      <legend className={clsx(classes.legend, classes.vr2)}>
        <Typography variant="body1" component="span">
          {formInstructions ? (
            formInstructions
          ) : (
            <FormattedMessage
              id="QUICK_ORDER_BULK_UPLOAD_INSTRUCTIONS"
              defaultMessage="Type or paste product SKU (product number and pack size), quantity, promo code and reference number separated by commas*. Enter separate products on new lines."
            />
          )}
        </Typography>
      </legend>
      <Grid
        container
        justifyContent={isMobile ? 'center' : 'flex-end'}
        spacing={5}
      >
        <Grid item xs={12} sm={8}>
          <Field
            name="bulkUploadMaterials"
            className={classes.bulkUploadInput}
            component={LiquidInputAdapter}
            size="large"
            filled
            fullWidth
            placeholder={formatMessage(messages.BULK_UPLOAD_ENTRY_EXAMPLE)}
            multiline
            minRows={6}
            maxRows={10}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
              const target = e.target as HTMLTextAreaElement
              setFieldTouched('bulkUploadMaterials')
              setFieldValue('bulkUploadMaterials', target.value)
              validate(target.value, setValidationErrors)
            }}
            onBlur={onBlur}
            inputProps={{
              'data-testid': 'material-numbers-input',
              'aria-describedby': 'bulk-upload-helper-text',
            }}
          />
          {helperText && <span id="bulk-upload-helper-text">{helperText}</span>}

          <Grid item xs={12} className={classes.validationError}>
            <ul>
              {Object.entries(pricingErrors || {}).map(
                ([productNumber, pricingError]) => {
                  const errorMsg = pricingError.errorMsg
                  const values = { productNumber }
                  // Some error messages have additional data that needs to be passed into formatMessage
                  if (errorMsg === 'CUSTOMER_NUMBER_BLOCKED') {
                    Object.assign(values, {
                      customerNumber: pricingError.paramList
                        ? pricingError.paramList[0]
                        : '',
                    })
                  }
                  if (
                    errorMsg ===
                    'EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE'
                  ) {
                    Object.assign(values, {
                      link: (
                        <a
                          href="https://www.emdmillipore.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Millipore
                        </a>
                      ),
                    })
                  }
                  if (
                    errorMsg ===
                    'MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE'
                  ) {
                    Object.assign(values, {
                      link: (
                        <a
                          href="https://merckmillipore.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Merck
                        </a>
                      ),
                    })
                  }

                  const showReplacementProducts = !(
                    !pricingError.replacementProducts?.length ||
                    pricingError.hideReplacementProductLink
                  )

                  return errorMsg === 'UNABLE_TO_GET_RESPONSE_FROM_PI' ? (
                    <li key={productNumber}>
                      <FormattedMessage
                        {...messages.PRICING_AND_AVAILABILITY_UNAVAILABLE}
                      />
                    </li>
                  ) : errorMsg === 'PRICING_AND_AVAILABILITY_UNAVAILABLE' ? (
                    <li key={productNumber}>
                      <FormattedMessage
                        {...messages.IS_NOT_A_VALID_PRODUCT_NUMBER}
                        values={values}
                      />
                    </li>
                  ) : errorMsg === 'THIS_PRODUCT_HAS_BEEN_DISCONTINUED' ? (
                    <li key={productNumber}>
                      <TrackedAvailabilityMessageProvider
                        source="bulk upload"
                        item={productNumber}
                        replacementProducts={
                          showReplacementProducts
                            ? pricingError.replacementProducts
                            : null
                        }
                      >
                        <TrackedAvailabilityMessage
                          {...messages[errorMsg]}
                          values={values}
                        />
                        {showReplacementProducts && (
                          <MuiLink
                            className={classes.modalLink}
                            onClick={() => {
                              setModalOpen(pricing[productNumber]?.data || null)
                            }}
                          >
                            {formatMessage(
                              messages[
                                pricingError.displaySimilarProductLabel
                                  ? 'VIEW_SIMILAR_PRODUCTS'
                                  : 'VIEW_REPLACEMENT_PRODUCTS'
                              ]
                            )}
                          </MuiLink>
                        )}
                      </TrackedAvailabilityMessageProvider>
                    </li>
                  ) : (
                    <li key={productNumber}>
                      <FormattedMessage
                        {...messages[errorMsg]}
                        values={values}
                      />
                    </li>
                  )
                }
              )}
            </ul>
          </Grid>

          {validationErrors.length > 0 && (
            <Grid item xs={12}>
              <ValidationErrors errors={validationErrors} />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          alignItems={isMobile ? 'center' : 'flex-end'}
          direction="column"
        >
          <Upload
            onChange={async ([file]) => {
              if (file) {
                const input = await parseSpreadsheet(file)

                const formattedEntry = formatEntry(input)

                validate(formattedEntry, setValidationErrors)
                setFieldTouched('bulkUploadMaterials')
                setFieldValue('bulkUploadMaterials', formattedEntry)
              }
            }}
            dropText={formatMessage(messages.DROP_CSV_XLS_FILE_OR)}
            accept=".csv,.xls,.xlsx, text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            clearInput
            browseText={formatMessage(messages.CHOOSE_FILE)}
          />
          <IconButton
            className={classes.bulkUploadTemplateButton}
            href={`/static/${fileName}`}
            download={fileName}
            data-dlname={fileName}
            data-event="download"
            data-dltype={dlType}
            onClick={() =>
              sendDownloadFileEvent(
                {
                  action: 'bulk upload template',
                },
                {
                  fileExtension: 'csv',
                  fileCategory: 'template',
                  component: 'body',
                  elementType: 'link',
                  linkText: 'download template',
                  linkUrl: `/static/${fileName}`,
                },
                true
              )
            }
          >
            <div className={classes.bulkUploadTemplateIcon}>
              <DownloadIcon />
            </div>
            <div className={classes.bulkUploadTemplateText}>
              {formatMessage(messages.DOWNLOAD_TEMPLATE)}
            </div>
          </IconButton>
        </Grid>
      </Grid>
    </fieldset>
  )
}

export default BulkUpload

export enum HeaderMenu {
  // This values are same as message.ts keys for getting the label.
  Logout = 'LOGOUT',
  YourProfile = 'YOUR_PROFILE',
  YourOrders = 'YOUR_ORDERS',
  YourQuotes = 'YOUR_QUOTES',
  ProductSourcing = 'PRODUCT_SOURCING',
  Cart = 'CART',
  Products = 'PRODUCTS',
  Services = 'SERVICES',
  Industries = 'INDUSTRIES',
  QuickOrder = 'QUICK_ORDER',
  SignUp = 'SIGN_UP',
  Login = 'LOGIN',
  Subscriptions = 'SUBSCRIPTIONS',
  SoftwareLibrary = 'SOFTWARE_LIBRARY',
  AldrichMarketSelect = 'ALDRICH_MARKET_SELECT',
}

export enum DejaNewEventLabel {
  QuickOrder = 'quick order',
  SavedCarts = 'saved carts',
  Lists = 'lists',
  Quotes = 'quotes',
  OrderHistory = 'order history',
  Account = 'account',
  Support = 'support',
  SDS_COA_COO = 'sds/coa/coo',
  Oligos = 'oligos',
  StructureSearch = 'structure search',
  GoToOrders = 'go to orders',
  GoToQuotes = 'go to quotes',
  GoToLists = 'go to lists',
  GoToInvoiceList = 'go to invoice list',
  ModalOrderDetails = 'modal link > order details',
  ModalQuoteDetails = 'modal link > quote details',
  ModalGoToLists = 'modal link > go to lists',
  ModalGoToSavedCarts = 'modal link > go to saved carts',
  ModalAddSelected = 'modal link > add selected',
  ModalAddAll = 'modal link > add all',
  FrequentlyPurchased = 'frequently purchased',
  SpecialOffers = 'Special Offers',
  PromotionsAndEvents = 'Promotions & Events',
  Programs = 'Programs',
  Webinars = 'Webinars',
  NewsAndOffers = 'News and Offers',
  FeaturedProducts = 'FeaturedProducts',
  HiUser = 'hi user > profile (ribbon)',
  RecentOrders = 'recent orders (ribbon)',
  RecentQuotes = 'recent quotes (ribbon)',
  NeedHelp = 'need help > maestro faq site (ribbon)',
  GoToSavedCarts = 'go to saved carts',
}

export enum DejaNewEventAction {
  Quotes = 'quotes',
  Lists = 'lists',
  RecentOrders = 'recent orders',
  LogInToViewHistoryAndFavorites = 'log in to view history and favorites',
  HomePage = 'home page',
  SavedCarts = 'saved carts',
}

export enum DejaNewEventCategory {
  DejaNew = 'deja new',
  QuickOrderPage = 'quick order page',
}

export enum EventKeys {
  IS_B2B_LOGGED_IN_CALLED = 'IS_B2B_LOGGED_IN_CALLED',
  RECOMMENDED_PRODUCTS = 'RECOMMENDED_PRODUCTS',
  FEATURED_PRODUCTS = 'FEATURED_PRODUCTS',
  VISITED_RECOMMENDED_PRODUCTS = 'VISITED_RECOMMENDED_PRODUCTS',
  VISIBLE_RECOMMENDED_PRODUCTS = 'VISIBLE_RECOMMENDED_PRODUCTS',
  CUSTOMER_VIEWED_PRODUCTS = 'CUSTOMER_VIEWED_PRODUCTS',
  RECENTLY_VIEWED_PRODUCTS = 'RECENTLY_VIEWED_PRODUCTS',
  PRODUCT_CARD_TYPE = 'PRODUCT_CARD_TYPE',
  IS_WECHAT_LOGGED_IN = 'IS_WECHAT_LOGGED_IN',
  POPULAR_PRODUCTS = 'POPULAR_PRODUCTS',
  NEW_ARRIVALS = 'NEW_ARRIVALS',
  BEST_SELLERS = 'BEST_SELLERS',
  BUY_AGAIN = 'BUY_AGAIN',
  RELATED_PRODUCTS = 'RELATED_PRODUCTS',
  PRODUCT_HERO_CARD = 'PRODUCT_HERO_CARD',
}
export enum ExtraEventValues {
  documentLibraryPage = 'document library page',
}

export enum GAEventTypes {
  ScrollEvent = 'scrollEvent',
}

export enum SearchPageEnum {
  TechnicalDocuments = 'technical documents',
  SiteContent = 'site content',
  BuildingBlocks = 'building blocks',
  StructureSearch = 'structure search',
}

export enum AdvancedSearchTypeEnum {
  Product = 'product',
  ProductExact = 'product exact',
  ProductNumber = 'product number',
  ProductName = 'product name',
  CasNumber = 'cas number',
  MolecularFormula = 'molecular formula',
  SubstanceId = 'substance id',
  FemaNumber = 'fema number',
  ColorIndex = 'color index',
  EgecNumber = 'egec number',
  EcNumber = 'ec number',
  GeneProduct = 'gene product',
  MdlNumber = 'mdl number',
  PubMed = 'pubmed',
  Protocol = 'protocol',
  Gene = 'gene',
  GeneSymbol = 'gene symbol',
  GeneId = 'gene id',
  TrcNumber = 'trc number',
  RefSeq = 'refseq',
  GeneDesc = 'gene desc',
}

export enum BuyInKItEventActionEnum {
  OpenPromoBundleModal = 'open promo bundle modal',
  ChangePromoBundle = 'change promo bundle',
  NeedMoreBundleOptions = 'need more bundle options',
  SubmitCustomBundleRequest = 'submit custom bundle request',
  ClosePromoBundleModal = 'close promo bundle modal',
  IgnorePromoBundleModal = 'ignore promo bundle modal',
}

export enum BuyItNowEventActionEnum {
  OpenProductDetailPage = 'open product detail page',
  SubmitOrder = 'submit order',
  EditBuyItNowCheckoutDetails = 'edit buy it now checkout details',
  CloseBuyItNowModal = 'close buy it now modal',
  IgnoreBuyItNowModal = 'ignore buy it now modal',
}

export enum AffiliationCheckoutEventActionEnum {
  CompleteCheckout = 'complete checkout',
  AffiliationPage = 'affiliation page',
}

export enum EmailLinkVerificationEnum {
  EmailVerification = 'email verification',
}

export enum EmailLinkVerificationActionEnum {
  VerificationSend = 'verification email sent',
  LinkExpired = 'verification failure',
  VerificationSuccessful = 'verification successful',
}

export enum RemoveFromCartEnum {
  ShoppingCart = 'shopping_cart',
}

export enum ProductDetailsEventAction {
  ViewPhoto = 'view photo',
  CooCoa = 'coo/coa link',
  AdditionalInfo = 'additional info',
  RelatedCategory = 'related categories',
  ShowMore = 'properties - show more',
  ProductComparison = 'product comparison guide',
  ViewSampleCoa = 'view sample coa',
  HowToEnterLotCoa = 'how to enter lot number (coa)',
  HowToEnterLotCoo = 'how to enter lot number (coo)',
  FaqShowMore = 'faq show more',
  ViewAllRelatedPapers = 'view all related papers',
  ContactTechnicalService = 'contact technical service',
  JumpToTop = 'jump to top',
  PreviousPurchase = 'previous purchase',
  ViewFullComparison = 'compare similar items - view full comparison',
  OpenItemPdp = 'compare similar items - open item pdp',
  OpenPDPRelatedProduct = 'open pdp - related products',
  pAndARelatedProducts = 'p&a - related products',
  CopyLink = 'share pdp - copy link',
  EmailLink = 'share pdp - email this product',
  ShowDifferences = 'compare similar items - show differences',
  ViewSampleCOAError = 'view sample coa - error modal',
}

export enum ProductDetailsEventCategory {
  ProductDetailsPage = 'product detail page',
  PricingAndAvailability = 'pricing and availability',
}

export enum SafetyAndRegulatoryEventCategory {
  ProductDetailsPage = 'product detail page',
  SafetyAndRegulatory = 'safety & regulatory',
}

export enum Dimension91 {
  Marketplace = 'marketplace',
  Standard = 'standard',
}

export enum SharedListEventAction {
  CreateList = 'create list',
  AddToList = 'add to list',
}

export enum SearchResultEventCategory {
  CASNumber = 'cas number',
  ECNumber = 'ec number',
}

export enum ExtendedCategoryValues {
  MarketplaceCheckoutPage = 'marketplace checkout page',
  MarketplaceOrderConfirmationPage = 'marketplace order confirmation page',
}

export enum ExtendedEventValues {
  OpenMilliporesigmaCart = 'open milliporesigma cart',
  OpenMarketplaceCart = 'open marketplace cart',
  SwitchToMarketplaceCart = 'switch-to marketplace cart',
  SwitchToMarketplaceCheckout = 'switch-to marketplace checkout',
  ViewMilliporesigmaCart = 'view milliporesigma cart',
  ViewMarketplaceCart = 'view marketplace cart',
  TrevipayLearnMore = 'trevipay learn more',
  TrevipayApplyNow = 'trevipay apply now',
  SaveMarketplaceCart = 'save marketplace cart',
  SubmitMarketplaceOrder = 'submit marketplace order',
}

export enum PaymentMethodValues {
  cc = 'credit card',
  po = 'po',
  chargeback = 'chargeback',
  mpower = 'mpower',
  rapidred = 'rapidred',
  cn = 'contracts',
  b2b = 'b2b',
  others = 'others',
}

export enum AddToCartPagesEnum {
  SearchResultPage = 'Search result page',
  StructureSearchResults = 'Structure search results page',
  BuildingBlockSearchResults = 'Building blocks search results page',
  ProductDetailPage = 'Product detail page',
  CustomList = 'Custom lists',
  Others = 'Others',
  ShoppingCart = 'Shopping cart',
  PaperDetails = 'Technical papers',
  QuickOrder = 'Quick order',
  QuickOrderQuotesModal = 'quick order - quotes modal',
  Substance = 'Substance page',
  QuoteDetails = 'quote details page',
  OrderDetails = 'Order details',
  OrderDetailsOrderSummary = 'Order details - order summary',
  GenePage = 'Gene page',
  RecentlyViewedProducts = 'Recently viewed products',
  RecommendedProducts = 'Recommended products',
  OrderTrackingRecommendedProducts = 'Order tracking recommended products',
  FeaturedProducts = 'featured products',
  RelatedProducts = 'Related products',
  CustomersAlsoViewed = 'Customers also viewed',
  SavedCart = 'Saved Cart',
  AvailabilityModal = 'Availability Modal',
  EmproveProducts = 'Emprove products',
  ProductCompare = 'Product compare pages',
  HeaderSearch = 'Header search',
  ShoppingCartPage = 'shopping cart page',
  ScanNowPage = 'Scan Now',
  BuyItAgainPage = 'Buy-It-Again page',
  SharedList = 'shared list',
  FrequentlyPurchasedProducts = 'Frequently purchased products',
  CartRecommendedProducts = 'cart_recommended_products',
  CompareSimilarItems = 'Compare similar items',
  BestSellerProducts = 'best sellers',
  PromoBundle = 'Promo bundle',
  B2BLandingPageQuickOrder = 'b2b landing page quick order',
  AccountDashboardQuote = 'account recent quotes',
  AccountDashboardOrder = 'account recent orders - buy it again',
  AccountDashboard = 'account dashboard page',
  ProductHeroCard = 'product hero card',
  BuyAgain = 'buy again',
  PopularProducts = 'popular products',
  NewArrivals = 'new arrivals',
}

export type RemoveFromCartPagesEnum = AddToCartPagesEnum

export enum AddressBookEnum {
  OrgAndMailingTab = 'org & mailing tab',
  ShippingTab = 'shipping tab',
  BillingTab = 'billing tab',
  StructureSearch = 'structure search',
  OrgAndMailing = 'organization & mailing',
  Shipping = 'shipping',
  Billing = 'billing',
}

export enum AddressBookLabel {
  EditMailing = 'edit > mailing',
  SwitchToShippingTab = 'switch to shipping tab',
  SwitchToBillingTab = 'switch to billing tab',
  SetDefaultShipping = 'set default > shipping',
  SwitchToOrgAndMailingTab = 'switch to org & mailing tab',
  SetDefaultNonCreditCard = 'set default > non-credit card',
  EditCreditCard = 'edit > credit card',
}

export enum FooterLinks {
  ExternalLinks = 'External merck links',
  TermsAndConditions = 'Terms and conditions',
}

export enum FullRegistrationEnum {
  StartFullRegistration = 'start full registration',
  AffiliationPage = 'full registration : affiliation page',
  ContactAndAddress = 'full registration : contact & addresses',
  ComplianceInformation = 'full registration : compliance information',
  TaxAndAgreement = 'full registration : tax & agreement',
  SubmitContactAddressInfo = 'submit contact & addresses info',
  SubmitComplianceInfo = 'submit compliance information info',
  CompleteFullRegistration = 'complete full registration',
}

export enum EventCategory {
  QuotesPage = 'quotes page',
  QuoteDetailsPage = 'quote details page',
  OrderCenter = 'order-center',
  Downloads = 'downloads',
  AddToCart = 'add to cart',
  CheckoutPage = 'checkout page',
  QuoteConfirmationPage = 'quote confirmation page',
}

export enum DocumentLibraryTab {
  RecentlyPurchase = 'Recently Purchased',
  RecentlyViewed = 'Recently Viewed',
  YourDocuments = 'Your Documents',
  ProtocolsAndArticles = 'Protocols And Articles',
}

export enum QmDocumentType {
  CofAnalysisDoc = 'Q14',
  CofOriginDoc = 'Q12',
  CofQualityDoc = 'COQ',
  SpecSheetDoc = 'SPEC',
}

export enum AbbreviatedQmDocumentType {
  COA = 'Q14',
  COO = 'Q12',
  COQ = 'COQ',
}

import React, { useState } from 'react'
import clsx from 'clsx'
import { Button, Theme, ButtonGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CaretRightIcon from '@icons/CaretRightIcon'
import CaretLeftIcon from '@icons/CaretLeftIcon'
import CaretDownIcon from '@icons/CaretDownIcon'
import { sendNavigationInteractionEvent } from '@src/utils/analytics'
import { FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  pageLabel: {
    display: 'none',
    marginRight: theme.spacing(3),
    '&.open': {
      '& svg': {
        transform: 'rotate(180deg)',
        transition: 'all 0.25s ease-in-out',
      },
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '& span': {
      display: 'inline-block',
      marginRight: '10px',
      color: theme.palette.primary.main,
      fontWeight: 900,
      cursor: 'pointer',
      '& > svg': {
        marginLeft: '10px',
      },
    },
  },
  buttonRoot: {
    height: 44,
    width: 44,
    [theme.breakpoints.up('md')]: {
      height: 32,
      width: 32,
    },
  },
  buttonOutlined: {
    borderColor: `${theme.palette.grey[500]} !important`,
    '&$buttonDisabled': {
      borderColor: `${theme.palette.grey[500]} !important`,
    },
  },
  buttonDisabled: {},
  buttonIcon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  buttonIconDisabled: {
    color: theme.palette.grey[500],
  },
  mobilePageCountLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(0, 4),
    minWidth: '160px',
    marginInline: '12px !important',
    borderRightColor: `${theme.palette.grey[500]} !important`,
    borderRadius: '5px !important',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dropdown: {
    listStyle: 'outside none none',
    position: 'absolute',
    background: theme.palette.common.white,
    zIndex: 1,
    borderRadius: '4px',
    padding: 0,
    margin: '0',
    overflow: 'hidden',
    minWidth: '214px',
    maxHeight: '0',
    top: '50px',
    left: '0',
    transition: 'max-height 0.25s ease-in-out',
    '&.open': {
      border: `solid 1px ${theme.palette.grey[500]}`,
      maxHeight: '130px',
      overflow: 'hidden auto',
      [theme.breakpoints.down('xs')]: {
        minWidth: '270px',
      },
    },
    '& > li': {
      display: 'block',
      padding: '.75rem',
      background: theme.palette.common.white,
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.primary.light,
      },
      '& > a': {
        color: theme.palette.common.black,
        fontWeight: 'normal',
        display: 'block',
      },
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  pageLabelWrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  countLabel: {
    textAlign: 'left',
  },
}))

interface Props {
  loading: boolean
  numPages: number
  currentPage: number
  handleUpdatePage: (page: number) => void
}

const CSRPagination: React.FC<Props> = ({
  loading,
  handleUpdatePage,
  currentPage,
  numPages = 0,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [pageOpen, setPageOpen] = useState<boolean>(false)

  const changePage = (
    selectedPage: number,
    detailType: string,
    elementType: string
  ) => {
    handleUpdatePage(selectedPage)
    setPageOpen(false)
    sendNavigationInteractionEvent({
      action: 'pagination click',
      detail: `${detailType} - page ${selectedPage} of ${numPages}`,
      section: 'products',
      component: 'body',
      elementType,
      elementText: detailType === 'jump' ? `page ${selectedPage}` : undefined,
    })
  }

  const firstPage = currentPage === 1
  const lastPage = currentPage === numPages

  const pageText = formatMessage({ id: 'PAGE', defaultMessage: 'Page' })
  const ofText = formatMessage({ id: 'OF', defaultMessage: 'of' })

  return (
    <div className={classes.pageLabelWrapper}>
      <div
        className={
          pageOpen ? `${classes.pageLabel} open` : `${classes.pageLabel}`
        }
      >
        <span
          onClick={() => setPageOpen(!pageOpen)}
          role="button"
          tabIndex={0}
          onKeyDown={() => setPageOpen(!pageOpen)}
        >
          {pageText} {currentPage}{' '}
          <CaretDownIcon className={clsx(classes.buttonIcon)} />
        </span>{' '}
        {ofText} {numPages}
        <ul
          className={
            pageOpen ? `${classes.dropdown} open` : `${classes.dropdown}`
          }
        >
          {Array(numPages)
            .fill(0)
            .map((_, i) => (
              <li key={`page${i}`}>
                <a
                  onClick={() => changePage(i + 1, 'jump', 'drop-down')}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => changePage(i + 1, 'jump', 'drop-down')}
                >
                  Page {i + 1}
                </a>
              </li>
            ))}
        </ul>
      </div>

      <ButtonGroup disableElevation disableFocusRipple disableRipple>
        <Button
          classes={{
            root: classes.buttonRoot,
            outlined: classes.buttonOutlined,
            disabled: classes.buttonDisabled,
          }}
          disabled={loading || firstPage}
          onClick={() => changePage(currentPage - 1, 'previous', 'button')}
        >
          <CaretLeftIcon
            className={clsx(classes.buttonIcon, {
              [classes.buttonIconDisabled]: firstPage,
            })}
          />
        </Button>
        <div
          className={
            pageOpen
              ? `${classes.mobilePageCountLabel} open`
              : `${classes.mobilePageCountLabel}`
          }
          onClick={() => setPageOpen(!pageOpen)}
          role="button"
          tabIndex={0}
          onKeyDown={() => setPageOpen(!pageOpen)}
        >
          <div>
            <FormattedMessage
              id="PAGINATION_OPTION_DISPLAY"
              defaultMessage="Page {page} of {numberOfPages}"
              values={{ page: currentPage, numberOfPages: numPages }}
            />
          </div>
          <CaretDownIcon className={clsx(classes.buttonIcon)} />
          <ul
            className={
              pageOpen ? `${classes.dropdown} open` : `${classes.dropdown}`
            }
          >
            {Array(numPages)
              .fill(0)
              .map((_, i) => (
                <li key={`page${i}`}>
                  <a
                    onClick={() => changePage(i + 1, 'jump', 'drop-down')}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => changePage(i + 1, 'jump', 'drop-down')}
                  >
                    Page {i + 1}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <Button
          classes={{
            root: classes.buttonRoot,
            outlined: classes.buttonOutlined,
            disabled: classes.buttonDisabled,
          }}
          disabled={loading}
          onClick={() => changePage(currentPage + 1, 'next', 'button')}
        >
          <CaretRightIcon
            className={clsx(classes.buttonIcon, {
              [classes.buttonIconDisabled]: lastPage,
            })}
          />
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default CSRPagination

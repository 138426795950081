import {
  GTMEventTypes,
  GTMEventCategory,
  GTMVirtualPageViewPayload,
  EventValues,
} from '@sial/common-utils'
import { Material, CartItem, RecentOrderItem } from '@src/types/graphql-types'
import { setMaterialData, setPaClipData } from '@utils/analytics/coreAnalytics'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { QuickCartItemFragment } from '@src/queries/AllCartsQuery.generated'

export type GTMAnalyticsEventPayload = {
  event?: GTMEventTypes.AnalyticsEvent
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventInteractionType?: 0 | 1
}

export interface MilliplexDetailsForGA {
  milliplexData: any
  productName: string
}

export interface GTMEcommerceValues {
  eventCategory: GTMEventCategory.OrderConfirmation
  eventAction: string
  eventLabel: string
  eventInteractionType: 1
  purchaseSoldToNumber: string
  purchaseBillToNumber?: string
  purchaseShipToNumber?: string
  purchaseParticipantId?: string
  ecommerce: {
    currencyCode: string
    purchase: {
      actionField: {
        id: string
        revenue: number | null
        tax?: number | null
        shipping?: number | null
        coupon?: string
        paymentMethod?: string
        shippingMethod?: string
      }
      products: GTMEcommerceItem[]
    }
  }
}

export type GTMPromotionViewPayload = {
  event?: GTMEventTypes.PromotionView
  ecommerce: GTMEcommerceValues
}

export type GTMProductDetailViewPayload = {
  event?: GTMEventTypes.ProductDetailView
  ecommerce: GTMEcommerceValues
}

export type GTMPurchaseConfirmationPayload = {
  event?: GTMEventTypes.PurchaseConfirmation
  eventCategory: GTMEventCategory.OrderConfirmation
  eventAction: string
  eventLabel: string
  purchaseSoldToNumber: string
  eventInteractionType: 1
  ecommerce: GTMEcommerceValues
}

export interface GTMEventProps {
  eventType: GTMEventTypes
  payload:
    | GTMVirtualPageViewPayload
    | GTMAnalyticsEventPayload
    | GTMPromotionViewPayload
    | GTMProductDetailViewPayload
    | GTMPurchaseConfirmationPayload
}

export type SearchAnalyticsEventParams = {
  searchType?: string
  href?: string
  item?: string
  searchTerm?: string
  facetValue?: string
}

export type RecommendedProduct = {
  id: string
  isVisible: boolean
  productNumber: string
}

export interface GTMVirtualPageViewPayloadWithPersistentLogin
  extends GTMVirtualPageViewPayload {
  persistentLogin?: string
}

export interface PageViewProps {
  url?: string
}

export interface CurrentStagedMaterial {
  material: Material
  promoCode?: string
  quantity: number
  error?: string
}

export interface ScanNowMaterial {
  quantity?: number
  materialNumber?: string
  brand?: string
  materialDescription?: string
  product?: string
}

export interface AddToCartGAData {
  products: GTMEcommerceItem[]
  pageName: string
  currency: string
}

export type LegacyUAFields = {
  eventCategory: string
  eventAction: string
  eventLabel: string | string[] | undefined
  eventInteractionType: number
}
export type ProductNumber = string
export type DocumentType = 'sds' | 'coo' | 'coa'
export type BarcodeScanEvent =
  | { action: 'search'; label: ProductNumber | 'bad scan' | 'scan again' }
  | { action: DocumentType; label: ProductNumber }
  | { action: EventValues.ExpandPandA; label: ProductNumber }
  | { action: 'view details'; label: ProductNumber }

export type BuyItAgainEvent =
  | { action: 'sort'; label: keyof RecentOrderItem }
  | { action: 'order detail'; label: string /* Order ID */ }
  | { action: 'product detail'; label: string /* Product number */ }
  | { action: 'repurchase order'; label: string /* Order ID */ }
  | { action: 'add to list'; label: string /* Material number */ }
  | { action: 'create list'; label: string /* User ID */ }

export type SaveForLaterAction =
  | 'save item'
  | 'move to cart'
  | 'delete item'
  | 'view all'
  | 'return to cart'
  | 'open pdp of saved for later item'

export type SaveForLaterEvent = {
  action: SaveForLaterAction
  label: string[] /* Product number */
}

export type BuyNowEvent = {
  action: 'buy now' | 'leave buy now'
  label: string[] /* Product number */
}

export type ClipboardCopyEvent = {
  action: 'click to copy'
  label: string[]
}

export type HeaderSearchEvent =
  | { action: 'quick look'; label: string /* Product number */ }
  | { action: 'sds'; label: string /* Product number */ }
  | { action: 'specification sheet'; label: string /* url */ }
  | { action: 'cas no'; label: string /* CAS number */ }
  | { action: 'buy it again'; label: string /* GA cookie */ }
  | { action: 'recent purchases'; label: string /* Product number */ }
  | { action: 'recent searches'; label: string /* Product number */ }

export type GA4SearchEventPayload = {
  eventName?: string
  searchTerm?: string
  searchAutoSuggest?: string
  searchAutoSuggestTerm?: string
  searchType?: string
  searchComponent: 'global search' | 'advanced search' | 'structure search'
  searchErrorMessage?: string
}

export type EditDeadlineModalLabel =
  | 'open'
  | 'close'
  | 'ignore'
  | 'cancel'
  | 'no change saved'
  | 'clear deadline'
  | 'only deadline updated'
  | 'deadline & reminder updated'
  | 'only reminder updated'
  | 'deadline not selected'
  | 'deadline selected - reminder not selected'
  | 'reminder updated'
  | 'deadline selected - reminder selected'
  | `reminder updated > ${string}`
  | 'reminder removed'
  | `deadline selected - reminder selected > ${string}`

export type ReminderDaysAnalytics = '24 hours' | '3days' | '1 week'

export type UserId = string | undefined
export type SharedListsEvent =
  | { action: 'view list'; label: UserId }
  | { action: ['sort', string /* sort option */]; label: UserId }
  | { action: 'edit list' | 'edit list - main page'; label: UserId }
  | { action: `create list - ${'private' | 'shared'}`; label: string }
  | { action: `duplicate list - ${'private' | 'shared'}`; label: string }
  | { action: 'delete list'; label: UserId }
  | { action: 'leave list'; label: UserId }
  | { action: 'add to list'; label: string /* material number */ }
  | { action: 'remove item'; label: string /* material number */ }
  | { action: 'clear list'; label: string[] /* material numbers */ }
  | { action: 'add a note'; label: UserId }
  | { action: 'edit note'; label: UserId }
  | { action: 'send invite'; label: UserId }
  | { action: 'resend invitation'; label: UserId }
  | { action: 'remove member'; label: UserId }
  | { action: 'accept invitation'; label: UserId }
  | { action: 'decline invitation'; label: UserId }
  | { action: 'full list added'; label: UserId }
  | { action: 'partial list added'; label: UserId }
  | { action: 'I am done'; label: UserId }
  | { action: 'done adding'; label: UserId }
  | {
      action: 'edit deadline & reminder modal'
      label: EditDeadlineModalLabel
    }
  | {
      action: 'request quote for full list'
      label: UserId
    }
  | {
      action: 'request quote for partial list'
      label: UserId
    }

export type GA4SharedListsEventPayload = {
  eventName?: string
  clickType: string
  clickAction: string
  clickDetails?: string | string[]
  component: string
  elementType: string
  linkText: string
  linkUrl?: string
}

export type GA4PDPClickPayload = {
  productId: string
  productName: string
  productBrand: string
  clickType: string
  clickAction: string
  clickDetails: string
  component: string
  elementType: string
  linkText: string | undefined
  linkUrl?: string
}

export type GARemoveCartItem = Pick<
  CartItem,
  'type' | 'currency' | 'pricing' | 'emproveDossierInfo' | 'quantity'
> & {
  material: CartItem['material'] | QuickCartItemFragment['material']
}

export type GA4CheckoutStayReturnPayload = {
  component: string
  eventType: string
  elementText: string
  elementType: string
  isMarketplace: boolean
  section?: string
  linkUrl?: string
}

export type GAMapProducts = typeof setMaterialData | typeof setPaClipData

export type DownloadFileEvent =
  | {
      action: EventActions.SDS
      brandKey: string
      productNumber: string
      language: string
    }
  | { action: EventActions.Quotes; quoteNumber: string }
  | { action: 'specification sheet' }
  | { action: 'more documents' }
  | { action: 'export orders list' }
  | { action: EventValues.OrderConfirmationPDF }
  | { action: 'bulk upload template' }

export type Menu = {
  L1item?: string | null
  L2item?: string | null
  L3item?: string | null
  L4item?: string | null
  linkUrl?: string
  ga4Only?: boolean
}

export type Footer = {
  title?: string | undefined | null
  item?: string | undefined | null
  elementType?: string
  navTreeOne?: string | null
  linkText?: string
  linkUrl?: string
  ga4Only?: boolean
}

export type GA4CheckAvailabilityEventPayload = {
  productId: string | undefined
  productVariant: string | undefined
  productName: string | undefined
  productBrand: string | undefined
}

export type GA4ProductDetailsEventPayload = {
  eventName?: string
  clickType: string
  clickAction: string
  clickDetails?: string
  productId?: string
  productName?: string
  productBrand?: string
  productVariant?: string
  component: string
  elementType: string
  linkText: string
  method?: string
  linkUrl?: string
}

export type GA4ViewSearchResultsPayload = {
  qFocus: string
  qPage: string
  qType: string
  searchResultStatus: string
  searchTerm: string
  didYouMeanTerms?: string
}

export type QuickOrderCategoryOverride = 'quotes page'

export type GA4QuickOrderTrackingPayload = {
  eventName?: string
  clickType: string
  clickAction: string
  clickDetails?: string
  component: string
  elementType: string
  linkText: string
  linkUrl?: string
}

export type ProductDetails = {
  productId?: string
  productBrand?: string
  productVariant?: string
  productName?: string
}
export type GA4QuickOrderInteractionPayload = {
  event?: string
  action?: string
  detail?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  userDetail?: string
  eventGroup?: string
  eventSubGroup?: string
}

export type OrderStatusInfoEvent = 'open' | 'close' | 'customer service'

export type RequestQuoteEvent = {
  category:
    | 'shopping cart page'
    | 'quotes page'
    | 'quote confirmation page'
    | 'order-center'
    | 'pricing and availability'
    | string
  //The string value should be a quote id
  action: 'view quote details' | 'request quote link' | 'request quote' | string
  label: string | undefined
}

export type ProductAnalytics = {
  product: SubstanceProductFieldsFragment & { position?: string }
  searchTerm: string
  productPosition?: string
  substanceId?: string
  substancePosition?: number
}

export type RemoveFromCartEvent = {
  productIds: string | null | undefined
  actionTitle: string
  ecommerce: {
    currencyCode: string | null | undefined
    remove: {
      actionField: {
        list?: string
      }
      products: GTMEcommerceItem[]
    }
  }
}

export enum EventActions {
  SDS = 'sds',
  Quotes = 'quotes',
  Configurator = 'configurator',
  Templates = 'templates',
  Summary = 'order summary',
  AddToCartErrors = 'addtocart errors',
  CustomerServiceInstruction = 'customer service instructions',
  OrderNotes = 'order notes',
}

export enum EventLabel {
  Template = 'template',
  CertificateDownload = 'certificate download',
  VectorDocumentDownload = 'vector document download',
  ShoppingCartPage = 'shopping cart page',
  CheckoutPage = 'checkout page',
}

export type AddToCartEvent = {
  productIds: string
  actionTitle: string | undefined
  ecommerce: {
    currencyCode: string
    add: {
      actionField: {
        list: string | undefined
      }
      products: Maybe<{
        id: string
        name: string | null | undefined
        variant: string
        brand: string | null | undefined
        quantity: number
        price: number | null
      }>[]
    }
  }
}

export type GTMEcommerceItem = {
  id: string
  name: string
  variant: string
  brand: string
  quantity: number
  price: number | null
  coupon?: string
  dimension91: string
  discount?: string
  erpType?: string
}

export type GA4DownloadFilePayload = {
  productId?: string
  lotNumber?: string
  errorDescription?: string
  component: string
  elementType: string
  linkText: string
  linkUrl?: string
  fileCategory?: string
  fileExtension?: string
  fileName?: string
  eventAction?: string
  eventLabel?: string
}

export type GA4CommonCartEventPayload = {
  event?: string
  action?: string
  detail?: string
  cartType?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  userDetail?: string
  eventGroup?: string
  eventSubGroup?: string
}

export type GA4CommonDetailClickEvent = {
  event: string
  action?: string
  detail?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  userDetail?: string
  eventGroup?: string
  eventSubGroup?: string
  productId?: string
  productName?: string
  productBrand?: string
  productVariant?: string
}

export type ViewSampleCOAEvent = {
  fileName: string | null | undefined
  fileExtension: string | null | undefined
  productId: string | null | undefined
  linkUrl: string | null | undefined
}

export type GA4CommonProductEvent = {
  event: string
  action?: string
  detail?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  userDetail?: string
  eventGroup?: string
  eventSubGroup?: string
  productId?: string
  productBrand?: string
  productVariant?: string
}

export type GA4CommonDetailProductIdEvent = {
  event: string
  action?: string
  detail?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  productId?: string
  userDetail?: string
  eventGroup?: string
  eventSubGroup?: string
}

export type GA4SharedListEventPayload = {
  event?: string
  action?: string
  detail?: string | string[]
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  userDetail?: string
  eventGroup?: string
  eventSubgroup?: string
  productId?: string
  productBrand?: string
  productVariant?: string
  productName?: string | null | undefined
}

export type GA4CSRFiltersEvent = {
  event: string
  action?: string
  detail?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
  coreEvent?: string
  userDetail?: string
  eventGroup?: string
  eventSubGroup?: string
  filterName?: string
  filterNameCount?: string
  filterCategory?: string
  filterExisting?: string
}

export type GA4ErrorEvent = {
  event?: string
  description?: string
  errorCategory?: string
  productId?: string
  productBrand?: string
  productVariant?: string
  productName?: string | null | undefined
  lotNumber?: string
  cartType?: string
}

export type GA4ViewPricingEventPayload = {
  component?: string
  elementType?: string
  elementText?: string
}

export interface ProductAliasPayload {
  action: string
  detail?: string | null
  component: string
  elementType: string
  elementText?: string | null
  linkUrl: string
  productId?: string
  productBrand?: string
}

export interface BaseProduct {
  id: string
  name: string
  variant: string
  quantity: number
  price: number | string | null
  brand: string
  dimension91: string
}

export interface SavedCartproducts extends BaseProduct {
  erpType?: string
}

export interface EmproveAddToCartData extends BaseProduct {
  brand: EventValues
}

export interface PaClipData extends Omit<BaseProduct, 'dimension91'> {
  price: number | null
}

export interface MaterialProductsData extends BaseProduct {
  coupon: string
  price: number | null
}

export interface PromoProductItems extends Omit<BaseProduct, 'dimension91'> {
  coupon?: string
  discount?: string
}

export type MonthsData = {
  value: string
  label: string
}

export type YearsData = MonthsData

export type CreditCardExpireDateData = {
  month: string
  year: string
}

export type FsApiFunction = (api: string, data: { uid: string }) => void

export interface ParsedBarCodeElement {
  ai: string
  dataTitle: string
  elementType: string
  data?: string | number | Date
  unit?: string
}

export interface ParseBarcodeData {
  codeName: string
  parsedCodeItems: ParsedBarCodeElement[]
}

export interface IdentifyAIofParsedBarCodeElement {
  element: ParsedBarCodeElement
  codestring: string
}

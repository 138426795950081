import React from 'react'
import { isObject } from 'lodash'
import { useUserSession } from '@utils/useUserSession'
import { useRouter } from '@src/routes'
import Link, { LinkProps } from 'next/link'
import { UrlObject } from 'url'

//Merged Next specific LinkProps and normal HTML anchor types and remove any overlapping props from the two using typescripts Omit. HTML attributes are derived from React in this case.
type ExtendedLinkProps = LinkProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>

//Simple function to check if a link (or URL object parameter) already has a valid country and language URL path.
const hasValidLinkPrefix = (hrefString: string, countryLangCode: string) => {
  const validPrefixes = [
    countryLangCode,
    '/[country]/[language]',
    '/[...rest]',
    'http',
  ]

  return validPrefixes.some((prefix) => hrefString.startsWith(prefix))
}

/*
  There are (albeit rare) instances where a path may come with or without a leading slash. Concatcenating this with the region code can be wonky.
  This handles it so we only end up with a single leading slash.
  Browsers are good at handling this themselves as well now. However for test coverage it is expected to be explicitly handled.
*/
const stripLeadingSlash = (pathString: string) => {
  if (pathString === '/') {
    return ''
  }

  return pathString.charAt(0) === '/' ? pathString.slice(1) : pathString
}

/*
  Performs the logic evaluation of URL parameters for Next <Link>. Next Link accepts either a string or Node URL object.
  Annoyingly the URL object can already have the fully formed 'href' assigned to it as well.
  So not only do we have to check on the objects pathname, we have to handle the href as just a string, or the href if it is nested one level deeper.

  References:
  - https://nextjs.org/docs/pages/api-reference/components/link#with-url-object
  - https://nextjs.org/docs/pages/api-reference/functions/use-router
  - https://microsoft.github.io/PowerBI-JavaScript/interfaces/_node_modules__types_node_url_d_._url_.urlobject.html
*/
const addRegAndLangPrefix = (
  urlData: UrlObject | string,
  countryLangCode: string
) => {
  if (
    typeof urlData === 'string' &&
    !hasValidLinkPrefix(urlData, countryLangCode)
  ) {
    urlData = `${countryLangCode}/${stripLeadingSlash(urlData)}`
  }

  if (
    isObject(urlData) &&
    urlData.path &&
    !hasValidLinkPrefix(urlData.path, countryLangCode)
  ) {
    urlData.path = `${countryLangCode}/${stripLeadingSlash(urlData.path)}`
  }

  if (
    isObject(urlData) &&
    urlData.href &&
    !hasValidLinkPrefix(urlData.href, countryLangCode)
  ) {
    urlData.href = `${countryLangCode}/${stripLeadingSlash(urlData.href)}`
  }

  if (
    isObject(urlData) &&
    urlData.pathname && //Pathname is legacy support. Modern URL Objects only support path and href.
    !hasValidLinkPrefix(urlData.pathname, countryLangCode)
  ) {
    urlData.pathname = `${countryLangCode}/${stripLeadingSlash(
      urlData.pathname
    )}`
  }

  return urlData
}

export const Linq: React.FC<ExtendedLinkProps> = (props) => {
  const linkParams = { ...props }

  // Create intl pathing flags based on current user session or router data. //
  const { userSession } = useUserSession()
  const router = useRouter()
  const usrLanguage = userSession.language || router.query?.language || 'en'
  const usrCountry = userSession.country || router.query?.country || 'US'

  const pathIntlFlags = `/${usrCountry.toUpperCase()}/${usrLanguage.toLowerCase()}`

  linkParams.href = addRegAndLangPrefix(linkParams.href, pathIntlFlags)

  if (linkParams.as) {
    linkParams.as = addRegAndLangPrefix(linkParams.as, pathIntlFlags)
  }

  return <Link {...linkParams} />
}

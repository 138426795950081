import gql from 'graphql-tag'

export const CURRENT_USER = gql`
  query CurrentUser {
    me {
      ...CurrentUser
    }
  }

  fragment CurrentUser on User {
    ... on LoggedInUser {
      ...LoggedInUser
    }
    ... on GuestUser {
      ...GuestUser
    }
  }

  fragment LoggedInUser on LoggedInUser {
    type
    userErpType
    id
    logonId
    weChatId
    memberId
    firstName
    lastName
    telephone
    mobilePhone
    mobileVerifiedFlag
    fax
    email
    title
    organizationName
    organizationId
    createdDate
    isDarmstadtUser
    isDirectCustomer
    userAdLink
    inviteCoWorkerAffiliation
    participantCommunicationSetting {
      isOffersPromotionsEmail
      orderConfirmationEmail
      attachmentConfirmationEmail
      shipmentTrackingEmail
      ccOrderConfirmationEmailIds
      bccOrderConfirmationEmailIds
      unpricedCcEmails
      emproveEmailVerification
      emproveTermsConditions
      invitationSent
      repromiseTrackingEmail
    }
    participantSitePreference {
      hideAddToCartForPrepackItems
      numberOfRecentOrders
      numberOfRecentQuotes
      numberOfEntryFields
      isRedirectToProductDetail
      websiteLocale
      productEntryPreference
      sdsLocale
      catalogFilter
      dealerTcs
      hideContractOption
      hideCustomProductBlanketPoOrdering
      hideOligoPeptideDesign
      hideBuildingBlocks
      preferredDealerId
      preferredDealerInfo {
        firstName
        lastName
        dealerId
        orgName
        department
        stateName
        city
        address
      }
    }
    participantComplianceInformation {
      ...ParticipantComplianceInformation
    }
    paymentMethods {
      requisitionNumber {
        label
        value
        mask
      }
      purchaseOrderNumber {
        label
        value
        mask
      }
      defaultPaymentMethod
      creditCard {
        cardNumber
        maskedCardNumber
        tokenizedCCNumber
        transientCCToken
        mask
        cardExpiryDate
        cardType
        cardNameLabel
        cardNameValue
        isRequired
        editableInCart
        displayableInEmail
        displayableInCart
        ccLegalTermsAcceptance
      }
      chargebacks {
        chargebackLabel
        chargebackCode
        value
        isDisplay
        isEditable
        isOnEmail
        isRequired
        partners {
          chargeback_payer
          chargeback_ship_to
          chargeback_sold_to
          chargeback_bill_to
        }
      }
      treviPayPaymentData {
        registrationStatus
        creditAvailable
        creditLimit
        errorCode
        existingRegisteredName
        existingRegisteredPhone
        creditMoreThanOrderValue
      }
    }
    contactAddress {
      ...CurrentUserAddress
    }
    shippingAddress {
      ...CurrentUserAddress
    }
    billingAddress {
      ...CurrentUserAddress
    }
    useShipToAddressForAllOrders
    allowBlankStreet
    roles {
      isCCRUser
      isPublicOrderingCustomer
      isCustomerServiceRep
      isExternalSalesAgent
      isPurchasingAgent
      isInventoryViewUser
      isOrderApprover
      isOrderRequester
      isDealer
      isOrderStatusCustomer
      isQuoteConverter
      isQuoteRequester
      isRegisteredCustomer
      isApprovee
      isMultiCountryUser
      isCustomProductCSR
      isOrderConsolidationRequester
      isOrderConsolidator
      isKoreaPrePayment
      isKoreaStandardCustomer
      isSourcingUser
      isDTAQFTBUser
      isInvoicePaymentCustomer
      isBuyingNetworkManager
      isPackForExportUser
    }
    roleNames
    soldToNumber
    abilities {
      canAddToCart
      canViewInventory
      canViewOnlineOrder
      canViewOnlineQuote
    }
    soldToPartners {
      participantId
      storeId
      soldTo
      shipTo
      billTo
      payer
      salesOrg
      isDefault
      contact
      e1Partner
    }
    cartPreferences {
      ...CartPreferences
    }
    metadata {
      isContractEnabled
      isSAPNextEnabled
      isOrderNotesEnabled
      guestShoppingType
      isBigFormFilled
      locale
      isBlanketPOEnabled
      isCreditCardEnabled
      emproveDomainStatus
      country
      wimsBlueOnRedEnabled
      isChatEnabled
      isBuildingBlockEnabled
      isDejaEnabled
      isDTAQZuCustomer
      isFddEnabled
      fddLevel
      isSingleLotEnabled
      isScanNowEnabled
      isDigitalProductEnabled
      passwordPatternModel {
        minimumCountMatch
        mandatoryPattern
        patterns
      }
      isRandRenabled
      isMarketplaceCatalogEnabled
      isMarketplaceCartEnabled
      isRememberMeEnabled
      isFullQuoteConversionEnabled
      isBlueErpIntegrationEnabled
      isQuoteEnabled
      canTryNewCartExperience
      isSwitchAccountsEnabled
      hasOnlyBlueERP
      helpLinkURL
      isNewPNASrpEnabled
      isInvoiceCenterEnabled
    }
    profileType
    b2bChannelInformation {
      logoDirectory
      isB2B
    }
    multiCountryPartnersInfo {
      participantId
      storeId
      country
      soldTo
      shipTo
      billTo
      payer
      pricingAvailabilitySetting
      salesOrg
      quantity
    }
    participantShippingPreference {
      participantId
      countryDefaultShippingEnabled
      countryDefaultShippingLocked
      participantShippingMethods {
        shipModeId
        shippingMethodCode
        description
        key
        isDisplay
        isDefault
      }
    }
    approvalConsolidationModel {
      delegateApproverId
      delegationApprovalExpirationDate
      isDelegateEnabled
      activate
    }
    isPriceUpliftCustomer
    adminPartnersInfo {
      storeId
      isDefault
      partners {
        partnersIndexId
        billTo {
          value
        }
        soldTo {
          value
        }
        shipTo {
          value
          restrictedPartners {
            value
            type
            restricedHiddenpartnersIndexId
          }
        }
        payer {
          value
        }
        contact {
          value
        }
        salesOrg
        distChannel
        erpSystemId
      }
    }
  }

  fragment GuestUser on GuestUser {
    type
    userErpType
    abilities {
      canAddToCart
      canViewInventory
    }
    metadata {
      isSAPNextEnabled
      isChatEnabled
      isBuildingBlockEnabled
      isDejaEnabled
      isSingleLotEnabled
      isOrderNotesEnabled
      isScanNowEnabled
      passwordPatternModel {
        minimumCountMatch
        mandatoryPattern
        patterns
      }
      isRandRenabled
      isMarketplaceCatalogEnabled
      isMarketplaceCartEnabled
      isRememberMeEnabled
      isFullQuoteConversionEnabled
      isBlueErpIntegrationEnabled
      hasOnlyBlueERP
      guestShoppingType
      canTryNewCartExperience
      helpLinkURL
      isNewPNASrpEnabled
      isInvoiceCenterEnabled
    }
  }

  fragment CartPreferences on CartPreferences {
    participantId
    cartShippingMethods {
      isLocked
      isEnabled
    }
    cartAddresses {
      isLocked
      isEnabled
    }
    yourReferenceField {
      isLocked
      isEnabled
    }
    promotionalCodeField {
      isLocked
      isEnabled
    }
    tariffCode {
      isLocked
      isEnabled
    }
    cartPaymentInfo {
      isLocked
      isEnabled
    }
    unNumber {
      isLocked
      isEnabled
    }
    cartOrderText {
      isLocked
      isEnabled
    }
    orderText {
      isLocked
      isEnabled
    }
    yourReferenceLabel
    defaultOpenLines
    defaultOpenLinesLocked
    defaultOpenLinesEnabled
    enableProductBlocking
    enableSingleLotB2B
    enableFDDForB2B
    fddLevelB2B
  }

  fragment CurrentUserAddress on UserAddress {
    addressId
    firstName
    middleName
    lastName
    telephone
    extension
    mobilePhone
    fax
    email
    officeRegistrationNumber
    organizationName
    organizationProperty
    principalInvestigator
    attentionTo {
      value
      locked
    }
    department {
      value
      locked
    }
    buildingRoom {
      value
      locked
    }
    stateLockable {
      value
      locked
    }
    postalCodeLockable {
      value
      locked
    }
    countryLockable {
      value
      locked
    }
    countryNameLockable {
      value
      locked
    }
    cityLockable {
      value
      locked
    }
    streetLockable {
      value
      locked
    }
    organizationNameLockable {
      value
      locked
    }
    postOfficeBoxLockable {
      value
      locked
    }
    district
    postOfficeBox
    street
    street2
    street3
    street4
    city
    state
    postalCode
    country
    countryName
    salutation
    title
    salesRepresentative
  }

  fragment PrimaryBusinessActivity on UserPrimaryBusinessActivity {
    cellCulture
    consultant
    cosmetics
    diagnosticsInVitro
    dogTrainer
    drugsAnimal
    drugsHuman
    electronics
    exporter
    exporterText
    foods
    government
    laboratory
    manufacturer
    manufacturerText
    medical
    resaleUnderOtherLabel
    resaleUnderSigmaAldrich
    resellerDistributor
    retailer
    testing
    university
    veterinaryClinic
    other
    otherText
  }

  fragment ParticipantComplianceInformation on UserParticipantComplianceInformation {
    participantId
    invoiceType
    invoiceTypeReason
    invoiceName
    bankName
    bankNo
    invoiceAddress
    invoiceContactPhoneNo
    organization
    organizationType
    position
    organizationWebsite
    productUsage
    labAndWarehouseFacilities
    vatTaxNumber
    salesVATTaxApplicable
    complianceTerms
    termsOfSale
    dealerTerms
    primaryBusinessActivity {
      ...PrimaryBusinessActivity
    }
  }
`

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import { Brand, CatalogImage } from '@src/types/graphql-types'
import { ProductDetailFragment } from '@src/fragments/ProductDetail.generated'
import HandleMarkup from '@src/components/HandleMarkup'
import vrStyles from '@src/styles/utils/vrStyles'

const { vr1, vr2 } = vrStyles

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: 'flex',
  },
  stackedAndCentered: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  mainContentImage: {
    height: 88,
    width: 88,
    flexShrink: 0,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[400]}`,
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
  },
  image: {
    // required for ie11 styling
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  mainContentText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(6),
  },
  productNumber: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  productName: {
    maxHeight: 66,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    '& sup, sub': {
      verticalAlign: 'baseline',
      position: 'relative',
      top: '-0.4em',
    },
    '& sub': {
      top: '0.1em',
    },
  },
  productBrand: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.25,
  },
  clampLines: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
  },
  mainContentHeight: {
    height: 120,
  },
  vr1,
  vr2,
}))

interface ProductLockupMiniProps {
  productNumber: ProductDetailFragment['productNumber']
  brand: Brand
  name: ProductDetailFragment['name']
  description: ProductDetailFragment['description']
  image?: Pick<CatalogImage, 'mediumUrl' | 'altText'>
  isRaisedCardLayout?: boolean
  displayDiscount?: (discountPercent?: string | null) => void
  discount?: string | null
}

export const ProductLockupMini: React.FC<ProductLockupMiniProps> = ({
  productNumber,
  brand,
  name,
  description,
  image,
  isRaisedCardLayout,
  displayDiscount,
  discount,
}) => {
  const displayProductNumber = !productNumber?.startsWith('CB_')
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.mainContent, {
        [classes.stackedAndCentered]: isRaisedCardLayout,
        [classes.mainContentHeight]: !!discount,
      })}
    >
      {image && (
        <div className={classes.mainContentImage}>
          <img
            className={classes.image}
            src={image.mediumUrl}
            alt={image.altText || description || name}
            width="112"
            height="50"
          />
        </div>
      )}
      {isRaisedCardLayout && <div className={classes.vr2}></div>}
      <div
        className={clsx({
          [classes.mainContentText]: !isRaisedCardLayout,
          [classes.stackedAndCentered]: isRaisedCardLayout,
        })}
      >
        <Typography className={classes.productBrand}>{brand.name}</Typography>
        {displayProductNumber && (
          <Typography className={classes.productNumber}>
            {productNumber}
          </Typography>
        )}
        {isRaisedCardLayout && <div className={classes.vr1}></div>}
        <Typography className={clsx(classes.productName, classes.clampLines)}>
          <HandleMarkup value={name} />
        </Typography>
        {displayDiscount && discount && displayDiscount(discount)}
      </div>
    </div>
  )
}
